global.$ = require("jquery");
global.jQuery = global.$;

// BOOTSTRAP JS & STYLES
import 'bootstrap';
import "../src/custom/scss/custom-template.scss";

//// CUSTOM STYLES, EXPORTS & JS

import "../src/custom/_custom-app.js";

/* # PLUGINS
========================================================================== */

//FANCYBOX

import "../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.js";
import "./template/ecommerce/plugins/fancybox/core.scss";
import "./template/ecommerce/plugins/fancybox/fullscreen.scss";
import "./template/ecommerce/plugins/fancybox/share.scss";
import "./template/ecommerce/plugins/fancybox/slideshow.scss";
import "./template/ecommerce/plugins/fancybox/thumbs.scss";

//TINY-SLIDER
//import "./ecommerce/plugins/tiny-slider/tiny-slider.scss";

//SWEETALERT

//TOASTR
import toastr from 'toastr';
window.toastr = toastr;

import "./template/ecommerce/plugins/toastr/toastr.scss";

///FONTAWESOME 5
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

//OWLCAROUSEL
import 'owl.carousel'
import "./template/ecommerce/plugins/owlcarousel/src/scss/owl.carousel.scss";
import "./template/ecommerce/plugins/owlcarousel/src/scss/_theme.default.scss";


/* # FUNCTIONS
========================================================================== */


$(function () {

    "use strict";
    var $window = $(window);
    var $document = $(document);
    var winWidthSm = 1024;
    var $body = $('body');

    $(document).ready(function () {
        //fullScreenSlider();
        //int_introHeight();
        scroll();
        sidebarMenu();
        filtersMenu();
        navigation_menu();
        //pluginElement();
        //sliderHero();
        //sliderAll();
        //containerGridMasonry();
        //scrollCallbackEle();
        //shortcodeElements();
    });


    // ---------------------------------------------------------------------------------------------------------------------------->
    // TOASTR   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->
    function LaunchErrorAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["error"](description, title)
    }
    
    function LaunchWarningAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["warning"](description, title)
    }
    
    function LaunchInfoAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 6000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["info"](description, title)		
    }
    
    function LaunchSuccessAlert(title, description) {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": true,
            "positionClass": "toast-top-full-width",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 100,
            "timeOut": 6000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        Command: toastr["success"](description, title)
    }

     // LANZAMOS ERRORES CON TOASTR
     $(".toastr-alert-error").each(function() {
        LaunchErrorAlert("", $(this).html());
    });
    $(".toastr-alert-warning").each(function() {
        LaunchWarningAlert("", $(this).html());
    });
    $(".toastr-alert-info").each(function() {
        LaunchInfoAlert("", $(this).html());
    });
    $(".toastr-alert-success").each(function() {
        LaunchSuccessAlert("", $(this).html());
    });

    // ---------------------------------------------------------------------------------------------------------------------------->
    // FANCYBOX   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    $('[data-fancybox="gallery"]').fancybox({
        // Options will go here
    });

    
    // ---------------------------------------------------------------------------------------------------------------------------->
    // SCROLL FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function scroll() {

        // //Click Event to Scroll to Top
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });
        $('.scroll-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 800);
            return false;
        });

        // Scroll Down Elements
        $('.scroll-down[href^="#"], .scroll-to-target[href^="#"]').on('click', function (e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

    };

    //---------------------------------------------------------------------------------------------------------------------------->
    //      Sidebar Menu
    //---------------------------------------------------------------------------------------------------------------------------->
    
    function sidebarMenu() {

        var sidebarMenuBtn = $('.sidebar-menu_btn'),
            sidebarMenu = $('.sidebar-menu'),
            sidebarMenuClose = $('.sidebar-close-icon'),
            sidebarBtnActive = 'active',
            sidebarMenuActive = 'sidebar-menu-open',
            sidebarBtnParentActive = 'cart-active';

        var openSidebar = function () {
            sidebarMenuBtn.toggleClass(sidebarBtnActive);
            sidebarMenu.toggleClass(sidebarMenuActive);
            sidebarMenuBtn.parent().toggleClass(sidebarBtnParentActive);
        }
        var closeSidebar = function () {
            sidebarMenuBtn.removeClass(sidebarBtnActive);
            sidebarMenu.removeClass(sidebarMenuActive);
            sidebarMenuBtn.parent().removeClass(sidebarBtnParentActive);
        }

        sidebarMenuClose.on('click', function (event) {
            closeSidebar();
        });

        $('.search-menu_btn, .cart-menu_btn').on('click', function (e) {
            closeSidebar();
        });

        sidebarMenuBtn.on('click', function (event) {
            event.stopPropagation();
            openSidebar();
        });

        $document.on('click touchstart', function (event) {
            if (!$(event.target).closest('.sidebar-menu, .sidebar-menu_btn').length) {
                closeSidebar();
            }
        });

    };

    
    function filtersMenu() {

        var filtersMenuBtn = $('.filters-menu_btn'),
            filtersMenu = $('.filters-menu'),
            filtersMenuClose = $('.filters-close-icon'),
            filtersBtnActive = 'active',
            filtersMenuActive = 'filters-menu-open',
            filtersBtnParentActive = 'cart-active';

        var openfilters = function () {
            filtersMenuBtn.toggleClass(filtersBtnActive);
            filtersMenu.toggleClass(filtersMenuActive);
            filtersMenuBtn.parent().toggleClass(filtersBtnParentActive);
        }
        var closefilters = function () {
            filtersMenuBtn.removeClass(filtersBtnActive);
            filtersMenu.removeClass(filtersMenuActive);
            filtersMenuBtn.parent().removeClass(filtersBtnParentActive);
        }

        filtersMenuClose.on('click', function (event) {
            closefilters();
        });

        $('.search-menu_btn, .cart-menu_btn').on('click', function (e) {
            closefilters();
        });

        filtersMenuBtn.on('click', function (event) {
            event.stopPropagation();
            openfilters();
        });

        $document.on('click touchstart', function (event) {
            if (!$(event.target).closest('.filters-menu, .filters-menu_btn').length) {
                closefilters();
            }
        });

    };

    //---------------------------------------------------------------------------------------------------------------------------->
    //      SearchBar Menu   
    //---------------------------------------------------------------------------------------------------------------------------->
    searchMenuBar();
    function searchMenuBar() {

        var searchMenuBtn = $('.search-menu_btn'),
            searchMenu = $('.search-menu-bar'),
            searchMenuClose = $('.search-close-icon'),
            searchBtnActive = 'active',
            searchMenuActive = 'search-menu-open',
            searchBtnParentActive = 'search-active';

        var closeSearchbar = function () {
            searchMenu.removeClass(searchMenuActive);
            searchMenu.siblings(searchMenuBtn).removeClass(searchBtnActive);
            searchMenu.parent().removeClass(searchBtnParentActive);
        }

        searchMenuBtn.on('click', function (e) {
            e.stopPropagation();
            if ($(this).hasClass(searchBtnActive)) {
                $(this).removeClass(searchBtnActive);
                $(this).siblings(searchMenu).removeClass(searchMenuActive);
                $(this).parent().removeClass(searchBtnParentActive);
            } else {
                searchMenuBtn.removeClass(searchBtnActive).siblings(searchMenu).removeClass(searchMenuActive).parent().removeClass(searchBtnParentActive);
                $(this).addClass(searchBtnActive);
                $(this).siblings(searchMenu).addClass(searchMenuActive);
                $(this).parent().addClass(searchBtnParentActive);
                $('input.seach-input').focus();
            }
        });

        searchMenuClose.on('click', function (e) {
            closeSearchbar();
        });

        $('.cart-menu_btn, .sidebar-menu_btn').on('click', function (e) {
            closeSearchbar();
        });

        $document.on('click touchstart', function (e) {
            if (!$(e.target).closest(searchMenu, searchMenuBtn).length) {
                searchMenuBtn.removeClass(searchBtnActive);
                searchMenu.removeClass(searchMenuActive);
                searchMenuBtn.parent().removeClass(searchBtnParentActive);
                e.stopPropagation();
            }
        });
    };

    //---------------------------------------------------------------------------------------------------------------------------->
    //      Cart Menu   
    //---------------------------------------------------------------------------------------------------------------------------->
    cartMenuBar();
    function cartMenuBar() {

        var cartMenuBtn = $('.cart-menu_btn'),
            cartMenu = $('.cart-menu-bar'),
            cartMenuClose = $('.cart-close-icon'),
            cartBtnActive = 'active',
            cartMenuActive = 'cart-menu-open',
            cartBtnParentActive = 'cart-active';

        var closeCartbar = function () {
            cartMenu.removeClass(cartMenuActive);
            cartMenu.siblings(cartMenuBtn).removeClass(cartBtnActive);
            cartMenu.parent().removeClass(cartBtnParentActive);
        }

        cartMenuBtn.on('click', function (e) {
            e.stopPropagation();
            if ($(this).hasClass(cartBtnActive)) {
                $(this).removeClass(cartBtnActive);
                $(this).siblings(cartMenu).removeClass(cartMenuActive);
                $(this).parent().removeClass(cartBtnParentActive);
            } else {
                cartMenuBtn.removeClass(cartBtnActive).siblings(cartMenu).removeClass(cartMenuActive).parent().removeClass(cartBtnParentActive);
                $(this).addClass(cartBtnActive);
                $(this).siblings(cartMenu).addClass(cartMenuActive);
                $(this).parent().addClass(cartBtnParentActive);
            }
        });

        cartMenuClose.on('click', function (e) {
            closeCartbar();
        });

        $('.search-menu_btn, .sidebar-menu_btn').on('click', function (e) {
            closeCartbar();
        });

        $document.on('click touchstart', function (e) {
            if (!$(e.target).closest(cartMenu, cartMenuBtn).length) {
                cartMenuBtn.removeClass(cartBtnActive);
                cartMenu.removeClass(cartMenuActive);
                cartMenuBtn.parent().removeClass(cartBtnParentActive);
                e.stopPropagation();
            }
        });
    };

    // ---------------------------------------------------------------------------------------------------------------------------->
    // NAVIGATION MENU FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->
    
    function navigation_menu() {

        var navMenuLink = $(".nav-menu > ul > li"),
            //menuIconBtn = $('.menu-dropdown-icon'),
            dropDown_Menu = $('.nav-dropdown'),
            nav_menu_item = $('.nav-menu-item'),
            nav_Mobile_Btn = $(".menu-mobile-btn"),
            nav_menu_wrap = $(".nav-menu");

        // Dropdown Menu Icon
        $('.nav-menu > ul > li:has( > .nav-dropdown)').prepend('<span class="menu-dropdown-icon"></span>');
        $('.nav-dropdown > ul > li:has( > .nav-dropdown-sub)').addClass('sub-dropdown-icon');
        $(".nav-menu > ul > li:has( > .nav-dropdown)").addClass('dd-menu-dropdown-icon');
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').addClass('sub-dropdown-icon');
            };
            if ($(window).width() <= winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').removeClass('sub-dropdown-icon');
            };
        });

        // Dropdown Menu
        navMenuLink.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        $('.menu-dropdown-icon').on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).siblings(".nav-dropdown").stop(true, false).fadeToggle(150);
            }
        });
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $(".nav-dropdown, .nav-dropdown-sub").fadeOut(150);
            }
        });

        // Sub Dropdown Menu
        nav_menu_item.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        nav_menu_item.on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
            }
        });

        // Dropdon Center align
        $window.on('resize', function () {
            dropDown_Menu.each(function (indx) {
                if ($(this).hasClass("center")) {
                    var dropdownWidth = $(this).outerWidth();
                    var navItemWidth = $(this).parents(nav_menu_item).outerWidth();
                    var dropdown_halfWidth = parseInt(dropdownWidth / 2);
                    var navItem_halfWidth = parseInt(navItemWidth / 2);
                    var totlePosition = parseInt(dropdown_halfWidth - navItem_halfWidth);
                    if ($window.width() > winWidthSm) {
                        $(this).css("left", "-" + totlePosition + "px");
                    } else {
                        $(this).css("left", "0");
                    };
                }
            });
        });

        // Mobile Menu Button
        nav_Mobile_Btn.on('click', function (e) {
            nav_menu_wrap.toggleClass('show-on-mobile');
            $(this).toggleClass("active");
            e.preventDefault();
        });

    };


    $('[data-toggle="tooltip"]').tooltip();
    
    // ------------------------------------------------------- //
    //   Home Slider
    // ------------------------------------------------------ //
    var homeSlider = $('.hero-slider');
    homeSlider.owlCarousel({
        loop: true,
        margin: 0,
        smartSpeed: 500,
        responsiveClass: true,
        //navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: false,
            },
            600: {
                items: 1,
                nav: false,
                dots: false
            },
            1120: {
                items: 1,
                dots: false,
                nav: false
            }
        },
        onRefresh: function () {
            homeSlider.height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });

    // ------------------------------------------------------- //
    //   Home Full Slider
    // ------------------------------------------------------ //

    var homeFullSlider = $('.home-full-slider');
    homeFullSlider.owlCarousel({
        loop: true,
        margin: 0,
        smartSpeed: 500,
        responsiveClass: true,
        //navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true
            },
            600: {
                items: 1,
                nav: false,
                dots: true
            },
            1120: {
                items: 1,
                dots: false,
                nav: true
            }
        },
        onRefresh: function () {
            homeFullSlider.find('.item').height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeFullSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });

    // ------------------------------------------------------- //
    //   Item Slider
    // ------------------------------------------------------ //
    $('.featured-items-slider').owlCarousel({
        loop: false,
        margin: 15,
        nav: false,
        dots: true,
        //navText: ['<img src="../img/prev.svg" alt="" width="50">', '<img src="../img/next.svg" alt="" width="50">'],
        smartSpeed: 400,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 2
            },
            1000: {
                items: 4
            }
        }
    });








});


